import React from 'react';
import { Container, Divider, Stack, Typography } from '@mui/material';

import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFoundPage = () => (
    <Layout>
        <Seo title="404: Not found" />
        <Container maxWidth="lg">
            <Stack direction="row" spacing={2} mt={8}>
                <Typography>404</Typography>
                <Divider orientation="vertical" flexItem />
                <Typography>
                    The requested page does not exist.
                </Typography>
            </Stack>
        </Container>
    </Layout>
);

export default NotFoundPage;
